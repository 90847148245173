import React, { useState } from 'react';
import styles from './styles.module.css';
import { mailicon } from 'resources/Images/images';
import { useNavigate } from 'react-router-dom';
import Foldable from 'components/foldable_component';
import List from 'components/List';

const Profile = () => {
  const navigate = useNavigate();

  const renderProfileArrrowSection = () => {
    return (
      <div className={styles.profileStyles}>
        <p
          className={styles.logoutTextStyles}
          onClick={() => {
            navigate('/signin');
          }}
        >
          Sign out
        </p>
      </div>
    );
  };

  const renderNameSection = () => {
    return (
      <div className={styles.nameSectionStyles}>
        <p className={styles.nameTextStyles}>JohnAndrew</p>
        <div className={styles.mailSectionStyles}>
          <div className={styles.mailImgStyles}>
            <img src={mailicon} alt="" className={styles.imageWidth} />
          </div>
          <p className={styles.mailTextStyles}>Andrew@gmail.com</p>
        </div>
      </div>
    );
  };

  const RenderHelpCenterSection = () => {
    const [textvalue, settextvalue] = useState('');
    return (
      <Foldable style_prop={{ paddingBottom: '5px' }} text={'Help Center'}>
        <textarea
       className={styles.borderOneStyles}
       
          value={textvalue}
          onChange={(e) => settextvalue(e.target.value)}
          placeholder="Enter your message..."
          
        />
        <br></br>
        <p className={styles.textWhite}>We will come back in 3-5 Days</p>
        <button
          onClick={() => {
            window.alert(textvalue);
          }}
          className={styles.helpCenterbuttonStyles}
          style={{
            width: 'min-content',
            fontSize: '9px',
            alignSelf: 'left',
            backgroundColor: 'purple',
          }}
        >
          submit
        </button>
      </Foldable>
    );
  };
  
  const RenderProfileSettingsSection = () => {
    const [textvalue1, settextvalue1] = useState('');
    const [textvalue2, settextvalue2] = useState('');
    return (
     
      <Foldable style_prop={{ paddingBottom: '5px' }} text={'Profile Settings'}>
        <textarea
        className={styles.borderOneStyles}
          value={textvalue1}
          onChange={(e) => settextvalue1(e.target.value)}
          placeholder="Enter your Email..."
        />
        <br></br>
        <textarea
        className={styles.borderOneStyles}
          value={textvalue2}
          onChange={(e) => settextvalue2(e.target.value)}
          placeholder="Enter your Password..."
        />
        <br></br>
        <p className={styles.textWhite}>Enter your account details to </p>
        <p className={styles.textWhite}>restore either your email or password</p> 
        <button
          onClick={() => {
            window.alert(textvalue1 + '\n' + textvalue2);
          }}
          className={styles.helpCenterbuttonStyles}
          style={{
            width: 'min-content',
            fontSize: '9px',
            alignSelf: 'left',
            backgroundColor: 'purple',
            color: 'white',
          }}
        >
          submit
        </button>
      </Foldable>
  
    );
  };
  
 
  return (
    <>
      <div className={styles.profileSection}>
        {renderProfileArrrowSection()}
        {renderNameSection()}
        <RenderHelpCenterSection />
        <RenderProfileSettingsSection />
      </div>
      <div
            style={{
              padding: '15px 15px 24px 15px',
              position: 'absolute',
              bottom: '0px',
              left: '0px',
              width: '100%',
            }}
          >
            <List map_off={true} value={2} />
          </div>
    
    </>
    
  );
};

export default Profile;
