import React, { useContext, useState } from 'react';
import styles from './styles.module.css';
import { appleicon, googleicon } from 'resources/Images/images';
import { AppDataContext } from 'providers/AppDataProvider';

import Input from 'components/Input';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { EmailValidationSchema } from '../../validators/Validator';
import { leftarrowicon } from 'resources/Images/images';
import {FiEye, FiEyeOff} from 'react-icons/fi';

const Login = () => {
  const navigate = useNavigate();
  const { strings } = useContext(AppDataContext);

  const [show, setShow] = useState(false);
  const [termsAndCondition, setTermsAndCondition] = useState(true);

  const handleBusinessSignUp = (values) => {
    console.log('emaill', values);
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(prevState => !prevState);
  }
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: EmailValidationSchema,
    onSubmit: handleBusinessSignUp,
  });
  const [passwordVisible, setPasswordVisible] = useState(false);

  const renderLoginBannerSection = () => {
    return (
      <div className={styles.bannerSectionStyles}>
        {/* <div className={styles.bannerParaSectionStyles}>
          <div onClick={() => navigate(-1)} className={styles.imageWidth}>
            <img
              src={leftarrowicon}
              alt=""
              className={styles.arrowIconStyles}
            />
        <div className={styles.bannerParaSectionStyles}>
          
          <div className={styles.GapSectionStyles}>
            <p className={styles.bannerHeaderStyles}>
              {strings.loginStrings.header}
            </p>
            <p className={styles.bannerDescpStyles}>
              {strings.loginStrings.descp}
            </p>
          </div>
          <p className={styles.signup}>{strings.loginStrings.SignUp}</p>
        </div> */}
        <div className={styles.GapSectionStyles}>
          <p className={styles.bannerHeaderStyles}>
            {strings.loginStrings.header}
          </p>
        </div>
        {loginFormSection()}
      </div>
    );
  };
 
  const loginFormSection = () => {
    return (
      <React.Fragment>
        <form className={styles.formSectionStyles}>
          {/* <div className={styles.formSectionStyles}> */}
          <div className={styles.formInputStyles}>
            <p className={styles.formTextStyles}>
              {strings.loginStrings.loginMail}
            </p>
        
            <Input
              name="email"
              placeholder={strings.signInStrings.emailId}
              type={'email'}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={() => formik.setFieldError('email', '')}
              error={formik.touched.email && formik.errors.email}
              style={{
                color: 'white',
                fontFamily: 'Arial',
                fontWeight: '1000',
              }}
            />
            </div>
           <div style={{position: 'relative'}}>
            <Input
               type={passwordVisible ? 'text' : 'password'}
              placeholder={'Password'}
              style={{ color: 'white', fontFamily: 'Arial', fontWeight: '1000' }}
            />
             <button type={'button'} 
             onClick={togglePasswordVisibility}
             style={{color: 'White', position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', background:'none', border:'none'}}>
              {passwordVisible ? <FiEyeOff  size={16}/> : <FiEye size={16} />} 
            </button>
            </div>
          <p className={styles.loginTextStyles}>
          </p>
          <a
            btName={strings.loginStrings.fpass}
            className={styles.forgotPassStyles}
            onClick={() => navigate('/forgotPassword')}
            style={{
              justifyContent: 'center',
              fontSize: '11px',
              display: 'flex',
            }}
          >
            Forgot Password
          </a>
          <Button
            btName={strings.loginStrings.signInBtn}
            onClick={() => navigate('/home')}
          />{' '}
          {/* </div> */}
          {/* onClick={() => navigate('/SignIn')} */}
          {/* </div> */}
        </form>
      </React.Fragment>
    );
  };

  const renderSignInBotttomSection = () => {
    return (
      <div className={styles.bottomSectionStyles}>
        <p
              btName={strings.loginStrings.signUp}
              onClick={() => navigate('/login')}
              style={{top: 'auto' , position:'relative', bottom: 'auto', color: '#c36eff',justifyContent:'ceneter' , left: '120px'}}
            >
              {strings.loginStrings.signUp}
            </p>
        <div className={styles.orSectionStyles}>
          
          <p className={styles.borderOneStyles}></p>
          
        
          <p className={styles.orTextStyles}>{strings.signInStrings.or}</p>
          <p className={styles.borderTwoStyles}></p>
        </div>
        <div className={styles.imageSectionStyles}>
          <div className={styles.googleImgStyles}>
            <img
              src={googleicon}
              alt="googleicon"
              className={styles.imageWrapperStytles}
            />
          </div>
          <div className={styles.appleImgStyles}>
            <img
              src={appleicon}
              alt="appleicon"
              className={styles.imageWrapperStytles}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={styles.splashContainerStyles}>
      {renderLoginBannerSection()}
      {renderSignInBotttomSection()}
    </div>
  );
};

export default Login;
