import React, { useContext, useState } from 'react';
import styles from './styles.module.css';
import { AppDataContext } from 'providers/AppDataProvider';
import Input from 'components/Input';
import Button from 'components/Button';
import { appleicon, googleicon } from 'resources/Images/images';
import { useFormik } from 'formik';
import { EmailValidationSchema } from 'validators/Validator';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const navigate = useNavigate();
  const { strings } = useContext(AppDataContext);

  const handleSignUp = (values) => {
    console.log('emaill', values);
    formik.resetForm();
  };
  const [show, setShow] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: EmailValidationSchema,
    onSubmit: handleSignUp,
  });

  const renderSignInMiddleSection = () => {
    return (
      <div className={styles.bannerSignInSectionStyles}>
        <div className={styles.signInMiddleSection}>
          {MiddleDescpSection()}
          {middleInputSection()}
        </div>
      </div>
    );
  };

  const MiddleDescpSection = () => {
    return (
      <div className={styles.descpStyles}>
        {/* <div onClick={() => navigate(-1)} className={styles.imageWidth}>
          // <img src={leftarrowicon} alt="" className={styles.arrowIconStyles} />
        </div> */}
        <p className={styles.descpOneStyles}>
          {strings.signInStrings.descpOne}
        </p>
        <p className={styles.descpTwoStyles}>
          {strings.signInStrings.descpTwo}
        </p>
      </div>
    );
  };

  const middleInputSection = () => {
    return (
      <div className={styles.signInInputStyles}>
        <p className={styles.signInMailTextStyles}>
          {strings.signInStrings.middleHeader}
        </p>
        {/* <div className={styles.gapOneStyles}> */}
        <form className={styles.gapOneStyles} onSubmit={formik.handleSubmit}>
          <Input
            name="email"
            placeholder={strings.signInStrings.emailId}
            type={'email'}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={() => formik.setFieldError('email', '')}
            error={formik.touched.email && formik.errors.email}
            style={{ color: 'white', fontFamily: 'Arial', fontWeight: '1000' }}
          />
          {show && middleInputSection()}
          <div className={styles.gapTwoStyles}>
            <Button btName={strings.signInStrings.signInBtn} type="submit" onClick={() => navigate('/otp')}/>
            {/* On the sumbit please check the format of the e-mail. After that on the onclick eventhandler make sure that the (E-Mail form) is acutaly filled in wi the correct format to proceed to the otp page :)*/}
            {/* <p
              onClick={(e) => {
                e.preventDefault();
                formik.validateForm().then((errors) => {
                  if (Object.keys(errors).length === 0) {
                    if (show) {
                      navigate('/otp');
                    } else {
                      setShow(true);
                    }
                  }
                });
              }}
              className={styles.alreadyLoginStyles}
            >
              <span className={styles.alreadyAccountStyles}>
                {strings.signInStrings.alreadyAccount} &nbsp;
              </span>
              <a onClick={() => navigate('/LogIn')}>
                {strings.signInStrings.login}
              </a>
            </p> */}
          </div>
        </form>
      </div>
    );
  };

  const renderSignInBotttomSection = () => {
    return (
      <div className={styles.bottomSectionStyles}>
        <div className={styles.orSectionStyles}>
          <p className={styles.borderOneStyles}></p>
          <p className={styles.orTextStyles}>{strings.signInStrings.or}</p>
          <p className={styles.borderTwoStyles}></p>
        </div>
        <div className={styles.imageSectionStyles}>
          <div className={styles.googleImgStyles}>
            <img
              src={googleicon}
              alt="googleicon"
              className={styles.imageWrapperStytles}
            />
          </div>
          <div className={styles.appleImgStyles}>
            <img
              src={appleicon}
              alt="appleicon"
              className={styles.imageWrapperStytles}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.splashContainerStyles}>
        {renderSignInMiddleSection()}
        {renderSignInBotttomSection()}
      </div>
    </>
  );
};

export default SignIn;
