import React, { useContext, useState } from 'react';
import styles from './styles.module.css';
import { appleicon, googleicon } from 'resources/Images/images';
import { AppDataContext } from 'providers/AppDataProvider';
import Header from 'components/Header';
import Input from 'components/Input';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { EmailValidationSchema } from '../../validators/Validator';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { strings } = useContext(AppDataContext);

  const [show, setShow] = useState(false);
  const [termsAndCondition, setTermsAndCondition] = useState(true);

  const handleBusinessSignUp = (values) => {
    console.log('emaill', values);

  };


  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: EmailValidationSchema,
    onSubmit: handleBusinessSignUp,
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(prevState => !prevState);
  }


  const renderLoginBannerSection = () => {
    return (
      <div className={styles.bannerSectionStyles}>
        <div className={styles.bannerParaSectionStyles}>
          <Header headerDescpName={strings.loginStrings.loginHeader} back_dest={"/login"} />
          <div className={styles.GapSectionStyles}>
            <p className={styles.bannerHeaderStyles}>
              {strings.loginStrings.header}
            </p>
            <p className={styles.bannerDescpStyles}>
              {strings.loginStrings.descp2}
            </p>
          </div>
        </div>
        {loginFormSection()}
      </div>
    );
  };
  const resetPage = () => {
    window.location.reload();
  };

  const loginFormSection = () => {
    return (
      <React.Fragment>
        <form className={styles.formSectionStyles}>
          {/* <div className={styles.formSectionStyles}> */}

          <div className={styles.formInputStyles}>
            <p className={styles.formTextStyles}>
              {strings.loginStrings.l2}
            </p>


            <Input
              type={passwordVisible ? 'text' : 'password'}
              placeholder={'Old password'}
              style={{ color: 'white', fontFamily: 'Arial', fontWeight: '1000' }}
            />
            <Input
              type={passwordVisible ? 'text' : 'password'}
              placeholder={'New password'}
              style={{ color: 'white', fontFamily: 'Arial', fontWeight: '1000' }}
            />
            <Input
              type={passwordVisible ? 'text' : 'password'}
              placeholder={'Confirm new password'}
              style={{ color: 'white', fontFamily: 'Arial', fontWeight: '1000' }}
            />
            <button type={'button'} onClick={togglePasswordVisibility}>
              {passwordVisible ? 'Hide' : 'Show'} password
            </button>
          </div>
          <Button
            btName={strings.loginStrings.conf}
            onClick={resetPage}
          />
          <p className={styles.loginTextStyles}>
            <span className={styles.alreadyAccTextStyles}>
              {strings.loginStrings.dontAccount}
            </span>

            &nbsp;{strings.loginStrings.signUp}
          </p>
          {/* </div> */}
        </form>
      </React.Fragment>
    );
  };

  const renderSignInBotttomSection = () => {
    return (
      <div className={styles.bottomSectionStyles}>
        <div className={styles.orSectionStyles}>
          <p className={styles.borderOneStyles}></p>
          <p className={styles.orTextStyles}>{strings.signInStrings.or}</p>
          <p className={styles.borderTwoStyles}></p>
        </div>
        <div className={styles.imageSectionStyles}>
          <div className={styles.googleImgStyles}>
            <img
              src={googleicon}
              alt="googleicon"
              className={styles.imageWrapperStytles}
            />
          </div>
          <div className={styles.appleImgStyles}>
            <img
              src={appleicon}
              alt="appleicon"
              className={styles.imageWrapperStytles}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={styles.loginSectionStyles}>
      {renderLoginBannerSection()}
      {renderSignInBotttomSection()}
    </div>
  );
};

export default ForgotPassword;
