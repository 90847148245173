import React, { useEffect } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
// import MarkerClusterer from '@google/markerclustererplus';

const apiOptions = {
  apiKey: 'AIzaSyDSlqHkVmlUN8uzLaMkIKoFwbcwZc666zc',
};

const MapComponent = () => {
  useEffect(() => {
    const loader = new Loader(apiOptions);

    loader.load().then(() => {
      initMap();
    });
  }, []);

  // const removeThing = () => {
  //   const mapDiv = document.querySelector('#map');
  //   const thingy = mapDiv.querySelector('.gmnoprint');

  //   const thingyDiv = thingy.parentElement;
  //   thingyDiv.style.display = 'none';
  // };

  const initMap = () => {
    const mapOptions = {
      center: {
        lat: 56.04594818882148,
        lng: 12.695346613871777,
      },
      zoom: 14,
      mapId: '5ad1f659c9ae8602',
      disableDefaultUI: true,
      backgroundColor: 'none',
    };

    const mapDiv = document.getElementById('map');

    if (window.google) {
      // Check if the 'google' object is defined
      const map = new window.google.maps.Map(mapDiv, mapOptions);

      // Define an array of marker positions with club names
      const clubs = [
        { position: { lat: 56.049, lng: 12.69 }, name: 'Club 1'},
        { position: { lat: 56.035, lng: 12.707 }, name: 'Club 2'},
        { position: { lat: 56.046, lng: 12.695 }, name: 'Club 3', },
        // Add more marker positions as needed
      ];

      // Create an array to store marker objects
      const markers = [];

      // Loop through the clubs array to create markers and info windows
      clubs.forEach((club) => {
        // Create a marker for each club
        const marker = new window.google.maps.Marker({
          position: club.position,
          map: map,
          title: club.name, // Set marker title to club name
        });
        markers.push(marker); // Push the marker object to the array

        // Create a unique <p> tag for each club
        const contentString = `
        <div style="background-color: rgba(240, 248, 255, 0); padding: 10px; border-radius: 5px;">
          <p style="font-size: 16px; font-weight: bold; color: purple; margin: 0 10px; position: relative; bottom: 5px; right: 7px;">${club.name}</p>
          <a id="infoWindowLink" href="/clubdetails" style="text-decoration:none; color:blue;>More details</a>
        </div>`;

        // Create an info window for each marker
        const infoWindow = new window.google.maps.InfoWindow({
          content: contentString,
        });
        let clickCount = 0;

        marker.addListener('click', () => {
          clickCount++;

          if (clickCount === 1) {
          
            infoWindow.open(map, marker);
          } else if (clickCount === 2) {
            window.location.href = '/clubdetails';
          }
        });
    });
    }
  };


  // const addMarkers = (map) => {
  //   const locations = {
  //     barangaroo: { lat: 56.050288680204694, lng: 12.69499027603652 },
  //   };

  //   const markers = [];
  //   for (const location in locations) {
  //     const markerOptions = {
  //       map: map,
  //       position: locations[location],
  //       icon: './img/custom_pin.png',
  //     };
  //     const marker = new window.google.maps.Marker(markerOptions);
  //     markers.push(marker);

  //     new window.google.maps.Marker(marker);
  //   }
  //   return markers;
  // };

  // const clusterMarkers = (map, markers) => {
  //   const clustererOptions = { imagePath: './img/m' };
  //   const markerCluster = new MarkerClusterer(map, markers, clustererOptions);
  // };

  // const addPanToMarker = (map, markers) => {
  //   let circle;
  //   markers.map((marker) => {
  //     marker.addListener('click', (event) => {
  //       const location = { lat: event.latLng.lat(), lng: event.latLng.lng() };
  //       map.panTo(location);
  //       if (circle) {
  //         circle.setMap(null);
  //       }
  //       circle = drawCircle(map, location);
  //     });
  //   });
  // };

  // const drawCircle = (map, location) => {
  //   const circleOptions = {
  //     strokeColor: '#000000',
  //     strokeOpacity: 0.8,
  //     strokeWeight: 1,
  //     map: map,
  //     center: location,
  //     radius: 800,
  //   };
  //   const circle = new window.google.maps.Circle(circleOptions);
  //   return circle;
  // };

  return (
    <>
      <div
        id="map"
        className={styles.map_remove}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          zIndex: '1',
          background: 'black',
        }}
      ></div>
    </>
  );
};

export default MapComponent;
